.wp-block-bnpp-custom-blocks-bnpp-featured-card-module  {


    .bnpp-featured-card-module-link {
        display: grid;
        text-decoration: none;
        color: currentColor;
        height: 100%;

        &:hover {

            .bnpp-featured-card-module-content {

                border: solid 1px var(--wp--preset--color--bnpp-green);

                h3 {
                    color: var(--wp--preset--color--bnpp-green);
                }
            }


        }
    }

    .bnpp-featured-card-module-content {
        border: solid 1px;
        padding: var(--wp--preset--spacing--60);
        height: 100%;

        h3 {
            text-transform: uppercase;
            width: min(100%, 300px);
        }

        p {
            width: min(100%, 300px);
        }
    }
    
}